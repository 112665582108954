
import { defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { mdiReload, mdiDelete } from '@mdi/js'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { ChecklistEntryOutput, ChecklistOutput } from '@/api/types/checklist'
import {
  useCreateChecklist,
  useDeleteChecklist,
  useGetChecklists,
  useUpdateChecklist,
  useUpdateChecklistEntry,
} from '@/api/checklist'
import { PersonOutput } from '@/api/types/person'
import { ChecklistTemplateId } from '@/api/types/checklistTemplate'
import { useGetChecklistTemplates } from '@/api/checklistTemplate'
import { CHECKLIST_ENTRY_STATUS } from '../../../types'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Checklists',
  components: {
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonAddEditDialog: () => import('@/components/common/CommonAddEditDialog.vue'),
    CommonAutocomplete: () => import('@/components/common/CommonAutocomplete.vue'),
  },
  props: {
    person: {
      type: Object as PropType<PersonOutput>,
      default: null,
    },
  },
  setup: (props, { root }) => {
    const { exec: getChecklists, data: checklists, isLoading: isLoadingChecklists } = useGetChecklists()
    const {
      exec: getChecklistTemplates,
      data: checklistTemplates,
      isLoading: isLoadingChecklistTemplates,
    } = useGetChecklistTemplates()
    getChecklistTemplates()

    const { addNotification } = useNotify()

    const isDeleteDialogOpen = ref(false)

    const activeChecklist = ref<ChecklistOutput | null>(null)

    const { deleteChecklist: deleteChecklistXhr } = useDeleteChecklist()

    const TABLE_HEADERS = [
      {
        text: root.$t('persons.profile.tabMenu.checklists.table.col.title.title'),
        value: 'checkListTemplate.name',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.checklists.table.col.title.checklistEntries'),
        value: 'checklistEntries',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    async function onDeleteChecklist(): Promise<void> {
      try {
        if (activeChecklist.value?.id) {
          await deleteChecklistXhr(activeChecklist.value.id)
        }

        addNotification({
          text: root.$t(`persons.profile.tabMenu.checklists.delete.message`) as string,
          type: 'success',
        })

        isDeleteDialogOpen.value = false
      } catch (error: unknown) {
        handleError(error)
      }

      init()
    }

    function onDelete(checklist: ChecklistOutput): void {
      activeChecklist.value = cloneDeep(checklist)

      isDeleteDialogOpen.value = true
    }

    const { updateChecklist } = useUpdateChecklist()

    async function onReload(checklist: ChecklistOutput) {
      const checklistForUpdate = {
        id: checklist.id,
        person: checklist.person.id,
        checkListTemplate: checklist.checkListTemplate.id,
      }

      try {
        await updateChecklist(checklist.id, checklistForUpdate)
      } catch (error: unknown) {
        handleError(error)
      }

      addNotification({
        text: root.$t('persons.profile.tabMenu.checklists.update.success') as string,
        type: 'success',
        timeout: 3000,
      })

      init()
    }

    async function init(): Promise<void> {
      await getChecklists({ params: { size: 9999, sort: 'id,asc', includeClosed: true, personIds: props.person.id } })

      checklists.value.map((template) =>
        template.checklistEntries.sort((a, b) => (a.checklistTask.name > b.checklistTask.name ? 1 : -1))
      )
    }
    init()

    const selectedChecklist = ref<ChecklistTemplateId | null>(null)

    function onCloseAddDeleteDialog(): void {
      isApplyChecklistDialogOpen.value = false

      isDeleteDialogOpen.value = false

      activeChecklist.value = {} as ChecklistOutput
      selectedChecklist.value = null
    }

    const isApplyChecklistDialogOpen = ref(false)

    function onClickApplyChecklist(): void {
      isApplyChecklistDialogOpen.value = true
    }

    const { createChecklist } = useCreateChecklist()

    async function onApplyChecklist(): Promise<void> {
      if (!selectedChecklist.value) return

      try {
        await createChecklist({
          person: props.person.id,
          checkListTemplate: selectedChecklist.value,
        })
      } catch (error: unknown) {
        handleError(error)
      }

      addNotification({
        text: root.$t('persons.profile.tabMenu.checklists.apply.success') as string,
        type: 'success',
        timeout: 3000,
      })

      init()
      onCloseAddDeleteDialog()
    }

    const { updateChecklistEntry } = useUpdateChecklistEntry()

    function getChecklistEntryColor(checklistEntry: ChecklistEntryOutput): string | undefined {
      let statusCheck: string

      if (checklistEntry.checklistEntryStatus === CHECKLIST_ENTRY_STATUS.OPEN) {
        statusCheck = ''
      } else if (checklistEntry.checklistEntryStatus === CHECKLIST_ENTRY_STATUS.CLOSED) {
        statusCheck = 'primary'
      } else {
        statusCheck = 'grey'
      }

      return statusCheck
    }

    function onChangeChecklistTaskStatus(checklistEntry: ChecklistEntryOutput, status: CHECKLIST_ENTRY_STATUS): void {
      try {
        updateChecklistEntry(checklistEntry.id, {
          id: checklistEntry.id,
          checklistEntryStatus: status,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    async function toggleChecklistEntryStatus(checklistEntry: ChecklistEntryOutput): Promise<void | undefined> {
      if (!hasSufficientRights(Rights.CHECKLIST_UPDATE)) {
        return
      }

      try {
        if (checklistEntry.checklistEntryStatus === CHECKLIST_ENTRY_STATUS.OPEN) {
          await updateChecklistEntry(checklistEntry.id, {
            id: checklistEntry.id,
            checklistEntryStatus: CHECKLIST_ENTRY_STATUS.CLOSED,
          })
        } else if (checklistEntry.checklistEntryStatus === CHECKLIST_ENTRY_STATUS.CLOSED) {
          await updateChecklistEntry(checklistEntry.id, {
            id: checklistEntry.id,
            checklistEntryStatus: CHECKLIST_ENTRY_STATUS.NOT_RELEVANT,
          })
        } else {
          await updateChecklistEntry(checklistEntry.id, {
            id: checklistEntry.id,
            checklistEntryStatus: CHECKLIST_ENTRY_STATUS.OPEN,
          })
        }
      } catch (error: unknown) {
        handleError(error)
      }

      init()
    }

    function returnChecklistEntryStatus(checklistEntryStatus: string): string {
      if (checklistEntryStatus === CHECKLIST_ENTRY_STATUS.OPEN) {
        return String(root.$t('persons.profile.tabMenu.checklists.checklistEntryStatus.open'))
      } else if (checklistEntryStatus === CHECKLIST_ENTRY_STATUS.CLOSED) {
        return String(root.$t('persons.profile.tabMenu.checklists.checklistEntryStatus.closed'))
      } else {
        return String(root.$t('persons.profile.tabMenu.checklists.checklistEntryStatus.notRelevant'))
      }
    }

    return reactive({
      icons: {
        mdiReload,
        mdiDelete,
      },
      constants: {
        TABLE_HEADERS,
        CHECKLIST_ENTRY_STATUS,
        Rights,
      },
      state: {
        checklists,
        isLoadingChecklists,
        checklistTemplates,

        isLoadingChecklistTemplates,

        selectedChecklist,
        activeChecklist,

        isDeleteDialogOpen,

        isApplyChecklistDialogOpen,
      },
      functions: {
        init,
        hasSufficientRights,

        toggleChecklistEntryStatus,
        getChecklistEntryColor,

        returnChecklistEntryStatus,
      },
      listeners: {
        onDelete,
        onReload,

        onDeleteChecklist,
        onApplyChecklist,

        onChangeChecklistTaskStatus,

        onClickApplyChecklist,
        onCloseAddDeleteDialog,
      },
    })
  },
})
