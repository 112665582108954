var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_CREATE) ? _c('v-btn', {
    staticClass: "mr-2 rounded-lg",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onClickApplyChecklist();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.checklists.apply.title')) + " ")]) : _vm._e()], 1), _c('v-data-table', {
    attrs: {
      "hide-default-footer": "",
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.checklists,
      "loading": _vm.state.isLoadingChecklists
    },
    scopedSlots: _vm._u([{
      key: "item.checklistEntries",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "my-2"
        }, _vm._l(item.checklistEntries, function (entry) {
          return _c('span', {
            key: entry.id
          }, [_c('v-tooltip', {
            staticClass: "mx-auto",
            attrs: {
              "top": "",
              "open-delay": "200"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-chip', _vm._g({
                  staticClass: "mr-1 my-1",
                  attrs: {
                    "color": _vm.functions.getChecklistEntryColor(entry),
                    "small": ""
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.functions.toggleChecklistEntryStatus(entry);
                    }
                  }
                }, on), [_c('span', [_vm._v(" " + _vm._s(entry.checklistTask.name) + " ")])])];
              }
            }], null, true)
          }, [_vm._v(" " + _vm._s(_vm.functions.returnChecklistEntryStatus(entry.checklistEntryStatus)) + " ")])], 1);
        }), 0)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          staticClass: "mx-auto",
          attrs: {
            "top": "",
            "open-delay": "200"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_UPDATE) ? _c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.listeners.onReload(item);
                  }
                }
              }, on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiReload) + " ")])], 1) : _vm._e()];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.checklists.reload')) + " ")]), _vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_DELETE) ? _c('v-btn', {
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.listeners.onDelete(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1) : _vm._e()];
      }
    }], null, true)
  }), _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.state.isApplyChecklistDialogOpen
    },
    on: {
      "add": function add($event) {
        return _vm.listeners.onApplyChecklist();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddDeleteDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.checklists.apply.title')) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return [_c('CommonAutocomplete', {
          attrs: {
            "items": _vm.state.checklistTemplates,
            "item-text": "title",
            "item-value": "id",
            "loading": _vm.state.isLoadingChecklistTemplates,
            "label": _vm.$t("persons.profile.tabMenu.checklists.apply.templates")
          },
          model: {
            value: _vm.state.selectedChecklist,
            callback: function callback($$v) {
              _vm.$set(_vm.state, "selectedChecklist", $$v);
            },
            expression: "state.selectedChecklist"
          }
        })];
      },
      proxy: true
    }])
  }), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddDeleteDialog();
      },
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteChecklist();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.checklists.deleteChecklist.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }